import React from 'react'
import  '../styles/global.css'
import CookieConsent from "react-cookie-consent";
import { Helmet } from 'react-helmet';


export default function Layout({ children }) {
    return (
    <div>      

    <Helmet>
        <title>Movember</title>
        <meta name="title" content="Movember" />
        <meta name="description" content="Veliki vodič kroz sve što trebate znati o muškom zdravlju" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://movember.centarzdravlja.hr/" />
        <meta property="og:title" content="Movember" />
        <meta property="og:description" content="Veliki vodič kroz sve što trebate znati o muškom zdravlju" />
        <meta property="og:image" content="" />
    </Helmet>		

    <CookieConsent
        location="bottom"
        buttonText="Prihvati"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics">
        <span style={{ fontSize: "13px" }}>U skladu s novom europskom regulativom, Centarzdravlja.hr je nadogradio politiku privatnosti i korištenja kolačića. Kolačiće upotrebljavamo kako bismo vam omogućili korištenje naše online usluge, što bolje korisničko iskustvo i funkcionalnost naših web stranica, prikaz reklamnih sadržaja i ostale funkcionalnosti koje inače ne bismo mogli pružati. Daljnjim korištenjem naše web stranice, suglasni ste s korištenjem kolačića. 
        <a className="policy" href="https://www.centarzdravlja.hr/info/uvjeti/izjava-o-privatnosti/" target="_blank" rel="noopener noreferrer"> Saznaj više</a>
        </span>
    </CookieConsent>

        {children}

    </div>
    )
}
