import React from 'react'
import  '../styles/global.css'

export default function Container( {children}) {
    return (
        <div className="container">
            {children}
        </div>
    )
}
