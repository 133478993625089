// extracted by mini-css-extract-plugin
export var primaryText = "home-module--primaryText--3rn4b";
export var quote = "home-module--quote--2aWcq";
export var textCenter = "home-module--textCenter--2F2nq";
export var backColor = "home-module--backColor--_0You";
export var imageBreak = "home-module--imageBreak--1WXpF";
export var hero = "home-module--hero--WrC6h";
export var overlay = "home-module--overlay--16pj2";
export var bfOverlay = "home-module--bfOverlay--3IoAh";
export var heroTitle = "home-module--heroTitle--2139s";
export var heroSection = "home-module--heroSection--2RP5n";
export var videoWrapper = "home-module--videoWrapper--2d-_y";